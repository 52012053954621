import Icon from '../components/Icon';

const WildlifeFilled = Icon(
  'wildlife-filled',
  <>
    <path
      fillRule="evenodd"
      d="M21.816 4.733c-.347-.449-1.018-.983-2.222-.983-1.58 0-3.606 1.058-5.42 2.827a13 13 0 0 0-1.424 1.629V4.75a.75.75 0 0 0-1.5 0v3.456a13 13 0 0 0-1.42-1.628C8.015 4.807 5.989 3.75 4.406 3.75c-1.203 0-1.875.534-2.222.982-.639.823-1.141 2.258-.02 6.676.568 2.234 1.83 3.093 2.872 3.414A4.125 4.125 0 0 0 12 19.244a4.125 4.125 0 0 0 6.964-4.422c1.042-.319 2.303-1.18 2.871-3.414.63-2.48 1.12-5.211-.02-6.675M12 9.25a.75.75 0 0 0-.75.75v7.25a.75.75 0 0 0 1.5 0V10a.75.75 0 0 0-.75-.75"
      clipRule="evenodd"
    />
  </>
);

export default WildlifeFilled;
