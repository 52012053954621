import Icon from '../components/Icon';

const PlaceMountainFilled = Icon(
  'place-mountain-filled',
  <>
    <path d="m7.61 7.152 1.745 3.668-3.444 7.43h1.653l3.58-7.723 3.45-7.363a1 1 0 0 1 1.812 0l7.106 15.161a1 1 0 0 1-.905 1.425H1.396a1 1 0 0 1-.903-1.43L5.804 7.152a1 1 0 0 1 1.806 0" />
  </>
);

export default PlaceMountainFilled;
