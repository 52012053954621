import Icon from '../components/Icon';

const Place = Icon(
  'place',
  <>
    <path
      fillRule="evenodd"
      d="M5.5 2v.003c3.23.078 6.108 1.648 8.596 3.109q.363.212.712.42c1.027.608 1.982 1.174 2.906 1.62 1.132.545 2.128.865 3.036.865a.75.75 0 0 1 .391 1.39c-4.102 2.506-7.951 3.124-10.802 3.581l-.69.112c-1.493.247-2.534.473-3.215.892-.317.195-.536.422-.683.705-.15.29-.251.687-.251 1.256V22A.75.75 0 0 1 4 22V2.5a.5.5 0 0 1 .5-.5zm0 10.81q.073-.05.148-.096c.976-.6 2.323-.857 3.756-1.094q.334-.055.681-.11c2.396-.386 5.312-.855 8.463-2.391-.5-.168-.995-.38-1.486-.617-.987-.476-2.01-1.082-3.04-1.692l-.685-.405C10.84 4.94 8.28 3.581 5.5 3.503z"
      clipRule="evenodd"
    />
  </>
);

export default Place;
