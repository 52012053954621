import AuthenticationType from '../enums/AuthenticationType';
import DeferredDeepLinkType from '../enums/DeferredDeepLinkType';
import PersistentAuthValue from '../enums/PersistentAuthValue';
import { getAmplitudeInstance } from '../amplitudeHelpers';

type logLogInSuccessParams = {
  authentication_type: AuthenticationType;
  deferred_deep_link_type?: DeferredDeepLinkType;
  persistent_auth?: PersistentAuthValue;
};

/**
 * User has logged in to an existing account successfully
 * @param params - Attributes to log with the amplitude event
 * @param params.authentication_type - Authentication method used to log in.
 * @param params.deferred_deep_link_type - Describes the type of deep link the user entered the app with to track attribution
 * @param params.persistent_auth - Describes whether user was auto logged in
 */
const logLogInSuccess = ({ authentication_type, deferred_deep_link_type, persistent_auth }: logLogInSuccessParams, callback?: () => void) => {
  getAmplitudeInstance().logEvent(
    'log in succeeded',
    { 'login type': authentication_type, 'deferred deep link type': deferred_deep_link_type, 'persistent auth': persistent_auth },
    callback
  );
};

export default logLogInSuccess;
