import Place from '@alltrails/denali/icons/Place';
import PlaceFilled from '@alltrails/denali/icons/PlaceFilled';
import Waterfall from '@alltrails/denali/icons/Waterfall';
import WaterfallFilled from '@alltrails/denali/icons/WaterfallFilled';
import Mountain from '@alltrails/denali/icons/PlaceMountain';
import MountainFilled from '@alltrails/denali/icons/PlaceMountainFilled';
import Wildflowers from '@alltrails/denali/icons/Wildflowers';
import WildflowersFilled from '@alltrails/denali/icons/WildflowersFilled';
import BirdWatching from '@alltrails/denali/icons/BirdWatching';
import BirdWatchingFilled from '@alltrails/denali/icons/BirdWatchingFilled';
import Wildlife from '@alltrails/denali/icons/Wildlife';
import WildlifeFilled from '@alltrails/denali/icons/WildlifeFilled';
import Beach from '@alltrails/denali/icons/Beach';
import BeachFilled from '@alltrails/denali/icons/BeachFilled';
import Lake from '@alltrails/denali/icons/Lake';
import LakeFilled from '@alltrails/denali/icons/LakeFilled';
import HotSpring from '@alltrails/denali/icons/HotSpring';
import HotSpringFilled from '@alltrails/denali/icons/HotSpringFilled';
import Views from '@alltrails/denali/icons/Views';
import ViewsFilled from '@alltrails/denali/icons/ViewsFilled';
import River from '@alltrails/denali/icons/River';
import RiverFilled from '@alltrails/denali/icons/RiverFilled';
import Cave from '@alltrails/denali/icons/Cave';
import CaveFilled from '@alltrails/denali/icons/CaveFilled';
import HistoricSite from '@alltrails/denali/icons/HistoricSite';
import HistoricSiteFilled from '@alltrails/denali/icons/HistoricSiteFilled';
import Camping from '@alltrails/denali/icons/Camping';
import CampingFilled from '@alltrails/denali/icons/CampingFilled';
import { IconComponent } from '@alltrails/denali/components/Icon';
import { POISubType } from '../types/algoliaResultTypes';

const getPOIIcon = (subtype: POISubType, filled?: boolean): IconComponent => {
  switch (subtype) {
    case 'peak':
      return filled ? MountainFilled : Mountain;
    case 'waterfall':
      return filled ? WaterfallFilled : Waterfall;
    case 'garden':
      return filled ? WildflowersFilled : Wildflowers;
    case 'bird_hide':
      return filled ? BirdWatchingFilled : BirdWatching;
    case 'wildlife_hide':
      return filled ? WildlifeFilled : Wildlife;
    case 'bay':
    case 'beach':
      return filled ? BeachFilled : Beach;
    case 'lake':
      return filled ? LakeFilled : Lake;
    case 'hot_spring':
      return filled ? HotSpringFilled : HotSpring;
    case 'viewpoint':
      return filled ? ViewsFilled : Views;
    case 'river':
      return filled ? RiverFilled : River;
    case 'cave':
      return filled ? CaveFilled : Cave;
    case 'archaeological_site':
    case 'historic_site':
    case 'ruins':
    case 'monument':
      return filled ? HistoricSiteFilled : HistoricSite;
    case 'campsite':
      return filled ? CampingFilled : Camping;
    default:
      return filled ? PlaceFilled : Place;
  }
};

export default getPOIIcon;
