import Icon from '../components/Icon';

const WaterfallFilled = Icon(
  'waterfall-filled',
  <>
    <path
      fillRule="evenodd"
      d="M6.94 3.474c.612-.52 1.426-.724 2.284-.724H20.25a1.5 1.5 0 0 1 .778 2.783.75.75 0 0 1-.528.217h-1a1.5 1.5 0 0 0-1.5 1.5v11.781a2 2 0 1 1 2 2.954L3.5 22a1.5 1.5 0 0 1-.062-2.999A2.125 2.125 0 0 1 6 16.093V5.713c.005-.92.31-1.701.94-2.239M10 5.25a.75.75 0 0 1 .75.75v6a.75.75 0 0 1-1.5 0V6a.75.75 0 0 1 .75-.75M14 10a.75.75 0 0 1 .75.75v5.75a.75.75 0 0 1-1.5 0v-5.75A.75.75 0 0 1 14 10"
      clipRule="evenodd"
    />
    <path d="M3 11.75a1 1 0 1 0 0-2 1 1 0 0 0 0 2M21 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
  </>
);

export default WaterfallFilled;
