import Icon from '../components/Icon';

const PlaceFilled = Icon(
  'place-filled',
  <>
    <path d="M4.5 2a.5.5 0 0 0-.5.5V22a.75.75 0 0 0 1.5 0v-6.047c0-.57.1-.966.25-1.256.148-.283.367-.51.684-.705.681-.42 1.722-.646 3.214-.892l.69-.112c2.851-.457 6.701-1.075 10.803-3.581a.75.75 0 0 0-.391-1.39c-.908 0-1.904-.32-3.036-.866-.924-.445-1.879-1.01-2.906-1.619q-.35-.208-.712-.42C11.608 3.65 8.73 2.08 5.5 2.003V2z" />
  </>
);

export default PlaceFilled;
