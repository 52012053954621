import Icon from '../components/Icon';

const Camping = Icon(
  'camping',
  <>
    <path
      fillRule="evenodd"
      d="M9.622 2.352a.75.75 0 0 1 1.026.27L12 4.94l1.352-2.318a.75.75 0 1 1 1.296.756l-1.78 3.05 8.063 13.822H22a.75.75 0 0 1 0 1.5H2a.75.75 0 0 1 0-1.5h1.07L11.131 6.43l-1.78-3.051a.75.75 0 0 1 .27-1.026M12 7.917 4.806 20.25h3.263l3.283-5.628a.75.75 0 0 1 1.296 0l3.283 5.628h3.263zm2.194 12.333L12 16.49 9.806 20.25z"
      clipRule="evenodd"
    />
  </>
);

export default Camping;
