import AuthenticationType from '../enums/AuthenticationType';
import DeferredDeepLinkType from '../enums/DeferredDeepLinkType';
import { getAmplitudeInstance } from '../amplitudeHelpers';

type logSignUpSuccessParams = {
  authentication_type: AuthenticationType;
  deferred_deep_link_type?: DeferredDeepLinkType;
};

/**
 * User has created a new account successfully
 * @param params - Attributes to log with the amplitude event
 * @param params.authentication_type - Authentication method used to sign up. This attribute alias was renamed from 'login type' to 'signup type' in 02-22.
 * @param params.deferred_deep_link_type - Describes the type of deep link the user entered the app with to track attribution
 */
const logSignUpSuccess = ({ authentication_type, deferred_deep_link_type }: logSignUpSuccessParams, callback?: () => void) => {
  getAmplitudeInstance().logEvent(
    'sign up succeeded',
    { 'signup type': authentication_type, 'deferred deep link type': deferred_deep_link_type },
    callback
  );
};

export default logSignUpSuccess;
