import Icon from '../components/Icon';

const HistoricSiteFilled = Icon(
  'historic-site-filled',
  <>
    <path
      fillRule="evenodd"
      d="M4.19 3.44A1.5 1.5 0 0 1 5.25 3H7.5a.5.5 0 0 1 .5.5V6h2V3.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V6h2V3.5a.5.5 0 0 1 .5-.5h2.25a1.5 1.5 0 0 1 1.5 1.5v4.19c0 .397-.158.778-.439 1.06a1 1 0 0 1-.093.078l-1.43 1.03.894 9.392h1.068a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1 0-1.5h1.068l.895-9.392-1.432-1.03a1 1 0 0 1-.092-.079A1.5 1.5 0 0 1 3.75 8.69V4.5c0-.398.158-.78.44-1.06M14.2 20.5l-.448-4.703-.002-.047a1.75 1.75 0 0 0-3.5 0q0 .024-.002.047L9.8 20.5z"
      clipRule="evenodd"
    />
  </>
);

export default HistoricSiteFilled;
