import Icon from '../components/Icon';

const HotSpringFilled = Icon(
  'hot-spring-filled',
  <>
    <path d="M14.25 6.937c0-.595.155-1.226.507-1.738.147-.213.242-.532.242-.887s-.095-.673-.242-.886a.75.75 0 0 1 1.235-.852c.353.512.508 1.143.508 1.738s-.155 1.227-.508 1.739c-.147.213-.242.531-.242.886s.095.674.242.887a.75.75 0 0 1-1.235.852c-.352-.512-.507-1.144-.507-1.739M20.286 11c.827 0 1.464.723 1.464 1.5v.875a9.75 9.75 0 0 1-1.03 4.36l-.05.1a.75.75 0 0 1-.29.312A3.7 3.7 0 0 1 21.714 21a.715.715 0 1 1-1.43 0 2.285 2.285 0 1 0-4.57 0 .715.715 0 1 1-1.43 0 2.285 2.285 0 0 0-4.57 0 .715.715 0 1 1-1.43 0 2.285 2.285 0 1 0-4.57 0 .715.715 0 1 1-1.43 0c0-1.146.52-2.172 1.336-2.853a.75.75 0 0 1-.292-.312l-.05-.1a9.75 9.75 0 0 1-1.029-4.36V12.5c0-.777.637-1.5 1.464-1.5zM11.757 5.2c-.352.51-.507 1.142-.507 1.737s.155 1.227.507 1.739a.75.75 0 1 0 1.235-.852c-.147-.213-.242-.532-.242-.887s.095-.673.242-.886c.353-.512.507-1.144.507-1.739s-.154-1.226-.507-1.738a.75.75 0 1 0-1.235.852c.147.213.242.531.242.886s-.095.674-.242.887M17.25 6.937c0-.595.155-1.226.507-1.738.147-.213.242-.532.242-.887s-.095-.673-.242-.886a.75.75 0 0 1 1.235-.852c.353.512.508 1.143.508 1.738s-.155 1.227-.508 1.739c-.147.213-.242.531-.242.886s.095.674.242.887a.75.75 0 0 1-1.235.852c-.352-.512-.507-1.144-.507-1.739" />
  </>
);

export default HotSpringFilled;
