import { useEffect, useState } from 'react';
import { useExperiments } from '@alltrails/experiments';
import CarouselDisplayTrigger from '@alltrails/amplitude/enums/CarouselDisplayTrigger';
import { useSelector } from 'react-redux';
import getAnalyticsParams from '../utils/getAnalyticsParams';
import { DeeplinkData } from '../types';
import useBaseBranchUrl from './useBaseBranchUrl';

const branchLinkFallback = 'https://alltrails.io/rgGVELw1wCb';

type BranchDeepLinkParams = {
  isEnabled: boolean;
  deeplinkData?: DeeplinkData;
  isOpen?: boolean;
  promptType?: 'banner' | 'interstitial';
  displayTrigger?: CarouselDisplayTrigger;
};

const useBranchDeeplink = ({ isEnabled, deeplinkData, promptType, displayTrigger }: BranchDeepLinkParams) => {
  const experiments = useExperiments();
  const browserName = useSelector((state: { browser?: UserAgentBrowser }) => state.browser?.name);
  const [deeplink, setDeeplink] = useState(branchLinkFallback);
  const baseBranchUrl = useBaseBranchUrl();

  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    let appPath = 'alltrails://screen/explore';

    if (deeplinkData) {
      const { pageType, pageId } = deeplinkData;
      appPath = `alltrails://${pageType}/${pageId}`;
    }

    const analyticsParams = getAnalyticsParams({ promptType, browserName, displayTrigger, experiments });
    const search = analyticsParams.toString();
    const params = search ? `&${search}` : '';

    // Branch long-link isn't built using URLParams to prevent serializing deeplink paths.
    // eslint-disable-next-line max-len
    const branchDeeplink = `${baseBranchUrl}&$ios_deeplink_path=${appPath}&$android_deeplink_path=${appPath}${params}`;
    setDeeplink(branchDeeplink);
  }, [promptType, displayTrigger, experiments, browserName, baseBranchUrl, isEnabled, deeplinkData]);

  return deeplink;
};

export default useBranchDeeplink;
