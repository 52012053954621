/**
 * Authentication method used to sign up or log in.
 */
enum AuthenticationType {
  Apple = 'apple',
  Email = 'email',
  Facebook = 'facebook',
  Google = 'google',
  GoogleOneTap = 'google one tap',
  PersistentAuthentication = 'persistent authentication'
}

export default AuthenticationType;
