import Icon from '../components/Icon';

const BeachFilled = Icon(
  'beach-filled',
  <>
    <path d="M12.828 5.3c1.02-.847 2.224-1.446 3.814-1.446 2.552 0 3.884 1.912 4.361 2.786.095.173-.076.389-.272.37A2.5 2.5 0 1 0 20.5 12h1.25a.5.5 0 0 1 .5.5v5c0 .612-.151 1.303-.611 1.855-.48.576-1.209.895-2.139.895-1.056 0-1.65-.698-2.028-1.14l-.099-.116c-.213-.243-.385-.418-.586-.543-.185-.114-.424-.2-.787-.2-.67 0-.977.287-1.47.78l-.02.02c-.507.507-1.199 1.2-2.51 1.2s-2.003-.693-2.51-1.2l-.02-.02c-.492-.493-.8-.78-1.47-.78-.363 0-.601.086-.787.2-.201.125-.373.3-.586.543l-.1.116c-.376.442-.97 1.14-2.027 1.14-.93 0-1.659-.319-2.139-.895-.46-.552-.611-1.243-.611-1.855v-4.754a.75.75 0 0 1 .75-.75c1.995 0 3.465-.475 4.641-1.168 1.19-.701 2.112-1.645 2.991-2.647q.27-.307.54-.625c.68-.794 1.382-1.614 2.156-2.256" />
  </>
);

export default BeachFilled;
