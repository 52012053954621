import Icon from '../components/Icon';

const CampingFilled = Icon(
  'camping-filled',
  <>
    <path
      fillRule="evenodd"
      d="M10.648 2.622a.75.75 0 1 0-1.296.756l1.78 3.05L3.069 20.25H2a.75.75 0 0 0 0 1.5h20a.75.75 0 0 0 0-1.5h-1.07L12.869 6.429l1.78-3.051a.75.75 0 1 0-1.296-.756L12 4.94zM8.427 20.253 12 14l3.573 6.253z"
      clipRule="evenodd"
    />
  </>
);

export default CampingFilled;
