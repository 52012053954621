import AuthenticationType from '../enums/AuthenticationType';
import { getAmplitudeInstance } from '../amplitudeHelpers';

type logSignUpFailedParams = {
  authentication_type: AuthenticationType;
  failure_type: string;
};

/**
 * User failed to sign up for an existing account
 * @param params - Attributes to log with the amplitude event
 * @param params.authentication_type - Authentication method used to attempt sign up.
 * @param params.failure_type - Reason for failure
 */
const logSignUpFailed = ({ authentication_type, failure_type }: logSignUpFailedParams, callback?: () => void) => {
  getAmplitudeInstance().logEvent('sign up failed', { 'signup type': authentication_type, 'failure type': failure_type }, callback);
};

export default logSignUpFailed;
