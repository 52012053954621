import AuthenticationType from '../enums/AuthenticationType';
import PersistentAuthValue from '../enums/PersistentAuthValue';
import { getAmplitudeInstance } from '../amplitudeHelpers';

type logLogInFailedParams = {
  authentication_type: AuthenticationType;
  failure_type: string;
  persistent_auth?: PersistentAuthValue;
};

/**
 * User failed to log in to an existing account
 * @param params - Attributes to log with the amplitude event
 * @param params.authentication_type - Authentication method used to attempt login.
 * @param params.failure_type - Reason for failure
 * @param params.persistent_auth - Describes whether user was auto logged in
 */
const logLogInFailed = ({ authentication_type, failure_type, persistent_auth }: logLogInFailedParams, callback?: () => void) => {
  getAmplitudeInstance().logEvent(
    'log in failed',
    { 'login type': authentication_type, 'failure type': failure_type, 'persistent auth': persistent_auth },
    callback
  );
};

export default logLogInFailed;
