import Icon from '../components/Icon';

const BirdWatching = Icon(
  'bird-watching',
  <>
    <path d="M16.25 6a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5" />
    <path
      fillRule="evenodd"
      d="M17.431 2.46a4.75 4.75 0 0 0-5.326 1.81L2.81 17.538a1 1 0 0 0-.047.067L1.386 19.57a.75.75 0 1 0 1.228.86l1.178-1.68H9.25V21a.75.75 0 0 0 1.5 0v-2.25H12q.631 0 1.25-.09V21a.75.75 0 0 0 1.5 0v-2.693a8.75 8.75 0 0 0 6-8.307V8.585l1.432-.358a.75.75 0 0 0 0-1.455l-1.468-.367a4.75 4.75 0 0 0-3.283-3.945M4.843 17.25l6.104-8.712a5.25 5.25 0 0 1-3.944 8.712zm8.672-7.015a6.75 6.75 0 0 0-1.688-2.953l1.508-2.153a3.25 3.25 0 0 1 5.915 1.869V10A7.25 7.25 0 0 1 12 17.25h-.758a6.75 6.75 0 0 0 2.273-7.015"
      clipRule="evenodd"
    />
  </>
);

export default BirdWatching;
