import Icon from '../components/Icon';

const LakeFilled = Icon(
  'lake-filled',
  <>
    <path d="M3.75 11a.48.48 0 0 1-.486-.5A8.75 8.75 0 0 1 12 2.25a8.75 8.75 0 0 1 8.736 8.25.48.48 0 0 1-.486.5zM2 13.25a.75.75 0 0 1 .75-.75h18.5a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1-.75-.75M5.5 15.5a.75.75 0 0 0 0 1.5h6.75a.75.75 0 0 0 0-1.5zM14.5 16.25a.75.75 0 0 1 .75-.75h3.25a.75.75 0 0 1 0 1.5h-3.25a.75.75 0 0 1-.75-.75M8 18.5A.75.75 0 0 0 8 20h8a.75.75 0 0 0 0-1.5z" />
  </>
);

export default LakeFilled;
