import Icon from '../components/Icon';

const ViewsFilled = Icon(
  'views-filled',
  <>
    <path
      fillRule="evenodd"
      d="M7.412 5.543a2.706 2.706 0 1 1-5.412 0 2.706 2.706 0 0 1 5.412 0M2.75 17a.75.75 0 0 0 0 1.5H21.5a.75.75 0 0 0 0-1.5zm1 3.75A.75.75 0 0 1 4.5 20H13a.75.75 0 0 1 0 1.5H4.5a.75.75 0 0 1-.75-.75m-1.192-7.588A5.8 5.8 0 0 0 2.08 15c-.025.275.202.5.478.5l18.888-.018c.28 0 .508-.233.476-.512-.226-2.029-1.45-3.951-2.415-5.388-.695-1.035-1.483-1.968-2.336-2.65-.849-.678-1.82-1.152-2.86-1.152-1.949 0-3.004 1.152-3.93 2.163l-.175.19c-.98 1.062-2.015 2.086-4.12 2.388-1.993.281-3.024 1.515-3.528 2.641"
      clipRule="evenodd"
    />
  </>
);

export default ViewsFilled;
