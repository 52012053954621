import { useFeatures } from '@alltrails/features';
import { useExperiments } from '@alltrails/experiments';
import CarouselDisplayTrigger from '@alltrails/amplitude/enums/CarouselDisplayTrigger';
import { DeeplinkData } from '../types';
import useAppsFlyerDeeplink from './useAppsFlyerDeeplink';
import useBranchDeeplink from './useBranchDeeplink';

/*
  useDeeplink creates deeplinks for location and trail pages to the app, defaulting to the homepage.
*/

type UseDeepLinkParams = {
  deeplinkData?: DeeplinkData;
  isOpen?: boolean;
  promptType?: 'banner' | 'interstitial';
  displayTrigger?: CarouselDisplayTrigger;
};

const useDeeplink = ({ deeplinkData, isOpen, promptType, displayTrigger }: UseDeepLinkParams) => {
  const experiments = useExperiments();
  const isAppsFlyerEnabled = experiments['web-appsflyer-migration']?.value === 'on';

  const getAppsFlyerDeeplink = useAppsFlyerDeeplink({
    deeplinkData,
    isOpen,
    promptType,
    displayTrigger,
    isEnabled: isAppsFlyerEnabled
  });
  const branchDeeplink = useBranchDeeplink({ deeplinkData, isOpen, promptType, displayTrigger, isEnabled: !isAppsFlyerEnabled });

  // AppsFlyer link needs to be able to be re-generated on click. Branch link is static.
  const getDeeplink = isAppsFlyerEnabled ? getAppsFlyerDeeplink : () => branchDeeplink;

  return getDeeplink;
};

export default useDeeplink;
