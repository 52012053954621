/**
 * Describes the type of deep link accessed by the user
 */
enum DeferredDeepLinkType {
  CollaborativeList = 'collaborative list',
  LiveShareTrackingPage = 'live share tracking page',
  Tagalong = 'tagalong',
  TrailPageHitTheTrail = 'trail page hit the trail'
}

export default DeferredDeepLinkType;
