import Icon from '../components/Icon';

const Lake = Icon(
  'lake',
  <>
    <path d="M12 3.75A7.25 7.25 0 0 0 4.75 11h-1.5A8.75 8.75 0 0 1 12 2.25 8.75 8.75 0 0 1 20.75 11h-1.5A7.25 7.25 0 0 0 12 3.75M2 13.25a.75.75 0 0 1 .75-.75h18.5a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1-.75-.75M5.5 15.5a.75.75 0 0 0 0 1.5h6.75a.75.75 0 0 0 0-1.5zM14.5 16.25a.75.75 0 0 1 .75-.75h3.25a.75.75 0 0 1 0 1.5h-3.25a.75.75 0 0 1-.75-.75M8 18.5A.75.75 0 0 0 8 20h8a.75.75 0 0 0 0-1.5z" />
  </>
);

export default Lake;
