import Icon from '../components/Icon';

const CaveFilled = Icon(
  'cave-filled',
  <>
    <path
      fillRule="evenodd"
      d="M7.815 4.195A.75.75 0 0 1 8.5 3.75h5a.75.75 0 0 1 .372.099l3.5 2a.75.75 0 0 1 .36.488l.064.288H15a.875.875 0 0 0 0 1.75h3.185l.51 2.292 1.89 2.364a.75.75 0 0 1 .158.37l1 7.5a.75.75 0 0 1-.743.849H3a.75.75 0 0 1-.746-.828l1-9.5a.75.75 0 0 1 .216-.452l.594-.595H8a.875.875 0 1 0 0-1.75H5.814l.057-.057zm2.948 9.818a1.75 1.75 0 0 1 2.474 0c.309.308.465.746.515 1.282l.43 4.955H9.818l.43-4.935c.104-.593.22-1.007.516-1.302"
      clipRule="evenodd"
    />
  </>
);

export default CaveFilled;
