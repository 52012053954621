import Icon from '../components/Icon';

const WildflowersFilled = Icon(
  'wildflowers-filled',
  <>
    <path d="M11.407 3.832c.144-.07.355-.154.593-.154s.449.083.593.154c.158.077.315.18.464.291.3.225.622.53.932.879.338.38.683.837.98 1.334.182-.23.396-.476.644-.724.938-.938 2.404-1.934 4.387-1.934a.75.75 0 0 1 .75.75c0 2.707-.711 5.014-2.031 6.727-1.327 1.722-3.23 2.794-5.506 3.076q-.232.024-.463.043v3.85c1.51-1.75 3.748-2.573 5.943-2.765a.75.75 0 0 1 .13 1.494c-2.697.236-5.146 1.496-6.039 4.241a.75.75 0 0 1-.78.89.75.75 0 0 1-.78-.89c-.893-2.745-3.342-4.005-6.04-4.241a.75.75 0 1 1 .131-1.495c2.191.192 4.426 1.014 5.935 2.758v-3.841l-.463-.044c-2.275-.282-4.18-1.354-5.506-3.076-1.32-1.713-2.031-4.02-2.031-6.727a.75.75 0 0 1 .75-.75c1.983 0 3.449.996 4.387 1.934.248.248.462.495.644.724.297-.497.642-.954.98-1.334.31-.35.632-.654.932-.879.15-.112.306-.214.464-.291" />
  </>
);

export default WildflowersFilled;
