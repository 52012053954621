import Icon from '../components/Icon';

const RiverFilled = Icon(
  'river-filled',
  <>
    <path d="M9.427 9.425c0 2.571 11.573-.5 11.573 3.353 0 3.216-7.217 1.722-7.217 3.96C13.783 18.399 16.235 18 18 18h2.5c1.332 0 1.996.75 2 1.5s-.653 1.5-2 1.5H9.427C6.217 21 3 20.94 3 19.073c0-3.216 6.717-3.86 6.717-5.142 0-1.935-6.717 0-6.717-3.217C3 8.143 8 8.032 8 6.75 8 6.025 7 5.5 5 5.5H3C1 5.5 1 3 3 3h5.035S14 3 14 5c0 2.5-4.573 2.5-4.573 4.425" />
  </>
);

export default RiverFilled;
