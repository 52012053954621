import Icon from '../components/Icon';

const BirdWatchingFilled = Icon(
  'bird-watching-filled',
  <>
    <path
      fillRule="evenodd"
      d="M17.43 2.46a4.75 4.75 0 0 0-5.324 1.81l-10.72 15.3a.75.75 0 1 0 1.228.86l1.178-1.68H9.25V21a.75.75 0 0 0 1.5 0v-2.25H12q.64 0 1.25-.08V21a.75.75 0 0 0 1.5 0v-2.655c3.564-1.097 6-4.315 6-8.345V8.585l1.432-.357a.75.75 0 0 0 0-1.456l-1.468-.367A4.75 4.75 0 0 0 17.43 2.46M9.905 17.25a6 6 0 0 0 2.738-3.208 6 6 0 0 0-1.26-6.141l-6.543 9.35zm6.221-11.5a.875.875 0 1 0 0 1.75.875.875 0 0 0 0-1.75"
      clipRule="evenodd"
    />
  </>
);

export default BirdWatchingFilled;
